import { useEffect } from "react";
import Aos from "aos";
import Marquee from "react-fast-marquee";
import ImageTitle from "./assets/images/img-title.png";
import ImageTitle2 from "./assets/images/img-title-2.png";
import ImageTitle3 from "./assets/images/img-title-3.png";
import ImageTitle4 from "./assets/images/img-title-4.png";
import IconSocial1 from "./assets/images/icon-1.png";
import IconSocial2 from "./assets/images/icon-2.png";
import IconSocial3 from "./assets/images/icon-3.png";
import IconSocial4 from "./assets/images/icon-4.png";
import VideoSection from "./assets/videos/video-section.mp4";
import ImageBox from "./assets/images/img-box-token.png";
import ImageValue1 from "./assets/images/value-1.png";
import ImageValue2 from "./assets/images/value-2.png";
import ImageValue3 from "./assets/images/value-3.png";
import ImageCat1 from "./assets/images/image-cat-1.png";
import ImageCat2 from "./assets/images/image-cat-2.png";
import ImageCat3 from "./assets/images/image-cat-3.png";
import ImageCat4 from "./assets/images/image-cat-4.png";
import ImageCat5 from "./assets/images/image-cat-5.png";
import ImageCat6 from "./assets/images/image-cat-6.png";
import ImageCat7 from "./assets/images/image-cat-7.png";
import ImageCat8 from "./assets/images/image-cat-8.png";
import ImageCat9 from "./assets/images/image-cat-9.png";
import ImageCat10 from "./assets/images/image-cat-10.png";
import ImageCat11 from "./assets/images/image-cat-11.png";
import ImageCat12 from "./assets/images/image-cat-12.png";
import ImageCat13 from "./assets/images/image-cat-13.png";
import ImageCat14 from "./assets/images/image-cat-14.png";
import ImageCat15 from "./assets/images/image-cat-15.png";
import ImageCat16 from "./assets/images/image-cat-16.png";

import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <div className="page-title">
              <img src={ImageTitle} alt="" />
            </div>
            <div className="content">protector of meow city</div>
            <ul className="socials">
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial1} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={IconSocial2} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/TheBatCat_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial3} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/TheBatCat_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconSocial4} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="section-story">
          <div data-aos="fade-up" className="container wrapper">
            <div className="page-title">
              <img src={ImageTitle2} alt="" />
            </div>
            <div className="content">
              <p className="des">
                In the bustling city of Meowham, Batcat was born to protect the
                night, his sleek black fur blending with the shadows. When the
                Catnip Gang threatened to wreak havoc, he sprang into action,
                using his acrobatic prowess and sharp claws to save the day.
              </p>
              <p className="address">CA: TBA</p>
            </div>
            <div className="video-section">
              <video autoPlay muted playsInline loop>
                <source src={VideoSection} type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
        <section className="section-tokenomics">
          <div data-aos="fade-up" className="container wrapper">
            <div className="page-title">
              <img src={ImageTitle3} alt="" />
            </div>
            <div className="content">
              <div className="box-content">
                <div className="img">
                  <img src={ImageBox} alt="" />
                </div>
                <div className="des">
                  <p className="title">Liquidity</p>
                  <img className="val-1" src={ImageValue1} alt="" />
                </div>
              </div>
              <div className="box-content">
                <div className="img">
                  <img src={ImageBox} alt="" />
                </div>
                <div className="des">
                  <p className="title">Liquidity</p>
                  <img className="val-2" src={ImageValue2} alt="" />
                </div>
              </div>
              <div className="box-content">
                <div className="img">
                  <img src={ImageBox} alt="" />
                </div>
                <div className="des">
                  <p className="title">Liquidity</p>
                  <img className="val-3" src={ImageValue3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-university">
          <div data-aos="fade-up" className="container wrapper">
            <div className="page-title">
              <img src={ImageTitle4} alt="" />
            </div>
            <div className="content">
              <Marquee>
                <div className="marquee">
                  <div className="img-slider">
                    <img src={ImageCat1} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat2} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat3} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat4} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat5} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat6} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat7} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat8} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat9} alt="" />
                  </div>
                </div>
              </Marquee>
              <Marquee direction="right">
                <div className="marquee">
                  <div className="img-slider">
                    <img src={ImageCat10} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat11} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat12} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat13} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat14} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat15} alt="" />
                  </div>
                  <div className="img-slider">
                    <img src={ImageCat16} alt="" />
                  </div>
                </div>
              </Marquee>
            </div>
            <div className="img-bottom"></div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <div className="page-title">
            <img src={ImageTitle} alt="" />
          </div>
          <p className="copyright">Copyright © 2024 $batcat</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
